import { ApplicationController } from '../javascript/controllers/application_controller.js';
import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = [
    'choiceList',
    'iconDeselect',
    'iconClosed',
    'iconOpen',
    'selectableChoice',
    'selectedChoice',
    'selectedChoices',
    'selectedChoicesField'
  ];

  static values = {
    isSelectOpen: { type: Boolean, default: false },
  };

  connect() {
    useClickOutside(this)
  };

  initialize() {
    this.isSelectOpen = false;
    this.toggleSelectableChoiceTargets();
    this.toggleSelectedChoiceTargets();

    if (JSON.parse(this.selectedChoicesFieldTarget.value).length >= this.selectedChoicesTarget.dataset.maxSelections) {
      this.iconClosedTarget.classList.add('tw--hidden');
    }
  };

  clickOutside(event) {
    this.selectClose();
  }

  deselectChoice() {
    var selected = event.target.parentNode.dataset;
    var selectedChoices = JSON.parse(this.selectedChoicesFieldTarget.value);

    if (selectedChoices.includes(selected.text)) {
      selectedChoices.splice(selectedChoices.indexOf(selected.text), 1);
      this.selectedChoicesFieldTarget.value = JSON.stringify(selectedChoices);
      this.selectedChoiceTargets[selected.index].classList.add('tw--hidden');
      this.selectableChoiceTargets[selected.index].classList.remove('tw--hidden');
    };

    this.selectClose();
  };

  selectChoice(){
    if (JSON.parse(this.selectedChoicesFieldTarget.value).length < this.selectedChoicesTarget.dataset.maxSelections) {
      var selected = event.target.dataset;
      var selectedChoices = JSON.parse(this.selectedChoicesFieldTarget.value);

      selectedChoices.push(selected.text);
      this.selectedChoicesFieldTarget.value = JSON.stringify(selectedChoices);

      this.selectedChoiceTargets[selected.index].classList.remove('tw--hidden');
      this.selectableChoiceTargets[selected.index].classList.add('tw--hidden');


      this.selectClose();
    };
  };

  selectClose() {
    this.iconClosedTarget.classList.remove('tw--hidden');
    this.iconOpenTarget.classList.add('tw--hidden');
    this.choiceListTarget.classList.add('tw--hidden');

    this.isSelectOpen = false;

    if (JSON.parse(this.selectedChoicesFieldTarget.value).length >= this.selectedChoicesTarget.dataset.maxSelections) {
      this.iconClosedTarget.classList.add('tw--hidden');
    }
  }

  selectOpen() {
    if (JSON.parse(this.selectedChoicesFieldTarget.value).length < this.selectedChoicesTarget.dataset.maxSelections) {
      this.iconClosedTarget.classList.add('tw--hidden');
      this.iconOpenTarget.classList.remove('tw--hidden');
      this.choiceListTarget.classList.remove('tw--hidden');

      this.isSelectOpen = true;
    };
  };

  selectToggle() {
    if (this.element !== event.target.parentNode.parentNode) { return };
    this.isSelectOpen ? this.selectClose() : this.selectOpen();
  };

  toggleSelectableChoiceTargets() {
    var availableChoices = this.selectableChoiceTargets;
    var selectedChoices = [];
    if (this.selectedChoicesFieldTarget.value != null) {
      selectedChoices = JSON.parse(this.selectedChoicesFieldTarget.value);
    }

    for(var i = 0; i < availableChoices.length; i++) {
      if(selectedChoices.includes(availableChoices[i].dataset.text)) {
        availableChoices[i].classList.add('tw--hidden');
      } else {
        availableChoices[i].classList.remove('tw--hidden');
      };
    }
  };

  toggleSelectedChoiceTargets() {
    var choices = JSON.parse(this.selectedChoicesFieldTarget.value);
    var selectedChoices = this.selectedChoiceTargets;

    for(var i = 0; i < selectedChoices.length; i++) {
      if (choices.includes(selectedChoices[i].dataset.text)) {
        selectedChoices[i].classList.remove('tw--hidden');
      } else {
        selectedChoices[i].classList.add('tw--hidden');
      };
    };
  };
};
