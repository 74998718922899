import { createPopper } from "@popperjs/core";
import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = ["element", "tooltip"];
  static values = {
    placement: {type: String, default: "bottom"}
  };

  connect() {
    let tooltipPlacement = this.tooltipTarget.dataset.placement;
    
    this.popperInstance = createPopper(this.elementTarget, this.tooltipTarget, {
      placement: tooltipPlacement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 2]
          }
        }
      ]
      
      
    });
  }

  show(event) {
    this.tooltipTarget.setAttribute("data-show", "");

    this.popperInstance.update();
  }

  hide(event) {
    this.tooltipTarget.removeAttribute("data-show");
  }

  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }
}