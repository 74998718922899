const subscribe = (eventName, callback) => {
  if (!callback) {
    throw `Cannot subscribe to ${eventName} with an undefined callback`;
  }

  const wrappedCallback = (event) => callback(event.detail);
  document.body.addEventListener(eventName, wrappedCallback);

  return () => document.body.removeEventListener(eventName, wrappedCallback);
};

const publish = (eventName, data = {}) => {
  const event = new CustomEvent(eventName, {
    detail: { ...data, sentAt: new Date().toISOString() },
  });

  document.body.dispatchEvent(event);
};

export const bus = {
  publish: publish,
  subscribe: subscribe,
};
