import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'mainmodal' ];

  static values = {
    isModalOpen: { type: Boolean, default: false }
  } 
  
  initialize() {
    this.isModalOpen = false;
  }

  toggle() {
    this.isModalOpen = !this.isModalOpen;
     
    this.mainmodalTarget.classList.toggle("tw--hidden");
  }
}