import { ApplicationController } from '../javascript/controllers/application_controller.js';

export default class extends ApplicationController {
  static targets = [
    'body',
    'iconClosed',
    'iconOpen',
    'title'
  ];

  static values = {
    isAccordionOpen: { type: Boolean, default: false }
  };

  initialize() {
    this.isAccordionOpen = false;
  };

  accordion_close() {
    this.iconClosedTarget.classList.remove('tw--hidden');
    this.iconOpenTarget.classList.add('tw--hidden');
    this.bodyTarget.classList.add('tw--hidden');

    this.isAccordionOpen = false;
  }

  accordion_open() {
    this.iconClosedTarget.classList.add('tw--hidden');
    this.iconOpenTarget.classList.remove('tw--hidden');
    this.bodyTarget.classList.remove('tw--hidden');

    this.isAccordionOpen = true;
  };

  toggle_accordion() {
    if (event.target == this.titleTarget) {
      this.isAccordionOpen ? this.accordion_close() : this.accordion_open();
    };
  };
};
