import baseWretch from "wretch";
import QueryStringAddon from "wretch/dist/addons/queryString";

import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  connect() {
    if (this.data.get('url')) this.reload(5000);
  }

  reload(delay) {
    let wretch = baseWretch().addon(QueryStringAddon);

    setTimeout(() => {     
      wretch.query()
            .get(this.data.get('url'))
            .notFound(() => {
              this.reload(delay * 2);
            })
            .text(html => {
              this.element.innerHTML = html;
            });
    }, delay);
  }
}
