import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static values = { copy: String }
  static targets = [ 'icon', 'text']

  copy() {
    navigator.clipboard.writeText(this.copyValue)
    this.switchText('Copied!')
    this.switchIcon('check_circle')

    setTimeout(() => {
      this.switchText('Copy Emails')
      this.switchIcon('copy_filled')
    }, 4000)
  }

  switchText(textValue) {
    if (!this.hasTextTarget) { return; }
    this.textTarget.textContent = textValue
  }

  switchIcon(iconValue) {
    if (!this.hasIconTarget) { return; }
    var useElement = this.iconTarget.querySelector('use');
    var iconURL = useElement.getAttribute('xlink:href');

    useElement.classList.toggle('tw--text-green-500');

    var parts = iconURL.split('#');
    iconURL = parts[0] + '#' + iconValue;
    useElement.setAttribute('xlink:href', iconURL);
  }
}
