
import { ApplicationController } from './application_controller.js';

export default class extends ApplicationController {
  static targets = [
    'currentUser',
    'leaderOption',
    'displayEmail',
    'displayName',
    'displayPhone'
  ];

  update_contact_details() {
    var selected_user_id = document.getElementById('group_contact_user_id').value;
    var available_users = this.leaderOptionTargets;

    for (var i = 0; i < available_users.length; i++) {
      var user = available_users[i];

      if (selected_user_id == '') {
        this.displayNameTarget.value = '';
        this.displayEmailTarget.value = '';
        this.displayEmailTarget.disabled = false;
        this.displayEmailTarget.classList.remove('tw--bg-secondary');
        this.displayEmailTarget.classList.remove('tw--text-disabled');
      } else if (user.dataset.id == selected_user_id) {
        this.displayNameTarget.value = user.dataset.displayName;
        this.displayEmailTarget.value = user.dataset.email;

        this.displayNameTarget.focus();

        // Disable email field if user is the current user
        if (this.currentUserTarget.value != selected_user_id) {
        // if (6 != selected_user_id) {
          this.displayEmailTarget.disabled = true;
          this.displayEmailTarget.classList.add('tw--bg-secondary');
          this.displayEmailTarget.classList.add('tw--text-disabled');
        } else {
          this.displayEmailTarget.disabled = false;
          this.displayEmailTarget.classList.remove('tw--bg-secondary');
          this.displayEmailTarget.classList.remove('tw--text-disabled');
        };
      };

    };
  };
};
