import { ApplicationController } from './application_controller.js';

export default class extends ApplicationController {
  static targets = [
    'address',
    'addressDetail',
    'childcare',
    'deliveryMethod',
    'groupTypeOption',
    'locationDetails',
    'locationDetailsText',
    'locationLabel',
    'previousLocationDetails',
  ];

  initialize() {
    if (this.deliveryMethodTarget.value == '') {
      this.deliveryMethodTarget.value = 'in_person'
    };

    this.update_group_type();
    this.update_location_address();
    this.update_location_details();
    this.update_location_label();
  };

  fill_details() {
    if (recentGroups == null) {
      return;
    }

    var location_id = document.getElementById('group_location_id').value.toString();
    var recentGroup = recentGroups.find(group => group.location_id == location_id);

    if (recentGroup == null) {
      return;
    }

    this.locationDetailsTarget.querySelector('textarea').value = recentGroup.room_location;
    document.getElementById('group_contact_name').value = recentGroup.contact_name;
    document.getElementById('group_contact_email').value = recentGroup.contact_email;
    document.getElementById('group_contact_position').value = recentGroup.contact_position;
    document.getElementById('group_contact_phone').value = recentGroup.contact_phone;
    document.getElementById('group_additional_information').value = recentGroup.additional_information;
  };

  toggle_child_care(){
    if (this.deliveryMethodTarget.value == 'online') {
      this.childcareTarget.classList.add('tw--hidden');
    } else {
      this.childcareTarget.classList.remove('tw--hidden');
    };
  };

  toggle_location_details_tooltip() {
    const tooltip = document.getElementById('group_location_details_tooltip');
    if (this.deliveryMethodTarget.value == 'online') {
      tooltip.textContent = 'Provide any instructions for your online meeting here.';
    } else {
      tooltip.textContent = `Describe what building and/or room your ${type} meets in.`;
    };
  };

  update_group_type() {
    var selected_delivery_method = undefined;

    if (this.hasDeliveryMethodTarget) {
      if (event.target.dataset != undefined) {
        selected_delivery_method = event.target.dataset.deliveryMethod;
        this.deliveryMethodTarget.value = selected_delivery_method;
      } else {
        selected_delivery_method = this.deliveryMethodTarget.value;
      };
    };

    // Set visible selection
    var available_types = this.groupTypeOptionTargets;
    for (var i=0; i < available_types.length; i++) {
      var gt = available_types[i];

      if (gt.dataset.deliveryMethod == selected_delivery_method) {
        gt.classList.add('tw--bg-tertiary', 'tw--outline', 'tw--outline-3', 'tw--outline-active', 'tw--border-transparent')
        gt.classList.remove('tw--border-primary');
      } else {
        gt.classList.remove('tw--bg-tertiary', 'tw--outline', 'tw--outline-3', 'tw--outline-active', 'tw--border-transparent')
        
      }gt.classList.add('tw--border-primary');
    };

    this.toggle_child_care();
    this.toggle_location_details_tooltip();
  };

  update_location_address() {
    var selected_location_id = document.getElementById('group_location_id').value;
    var available_locations = this.addressTargets;

    for (var i = 0; i < available_locations.length; i++) {
      if (available_locations[i].dataset.locationId == selected_location_id) {
        available_locations[i].classList.remove('tw--hidden');
      } else {
        available_locations[i].classList.add('tw--hidden')
      };
    };
  };

  update_location_details() {
    if (this.deliveryMethodTarget.value == 'online') {
      this.locationDetailsTextTarget.innerHTML = 'Online Meeting Details';
    } else {
      this.locationDetailsTextTarget.innerHTML = 'Meeting Location Details';
    };
  };

  update_location_label() {
    if (this.deliveryMethodTarget.value == 'online') {
      this.locationLabelTarget.textContent = 'Meeting Host';
      document.getElementById('meeting_location_tooltip_icon').classList.remove('tw--hidden');
    } else {
      this.locationLabelTarget.textContent = 'Meeting Location';
      document.getElementById('meeting_location_tooltip_icon').classList.add('tw--hidden');

    };
  };
};
