import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'version' ];


  update(event) {
    this.versionTarget.value = event.target.value;
  }
}
