import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'sidebarMenu', 'sidebarIcon', 'menuDropDown', 'menuIcon', 'blanketOverlay' ];

  static values = {
    isSidebarOpen: { type: Boolean, default: false },
    isMenuOpen: { type: Boolean, default: false },
  } 
  
  initialize() {
    this.isSidebarOpen = false;
    this.isMenuOpen = false;
  }

  toggleMenu(e) {
    const dropdown = this.menuDropDownTargets.find(target => target.id == e.params.id);
    const icon = this.menuIconTargets.find(target => target.id == e.params.id).getElementsByTagName('use')[0];
    this.isMenuOpen ? this.collapseMenu(dropdown, icon) : this.expandMenu(dropdown, icon);
    this.isMenuOpen = !this.isMenuOpen;
  }

  toggleSidebar() {
    this.isSidebarOpen ? this.collapseSidebar() : this.expandSidebar();
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  closeSidebar() {
    this.collapseSidebar();
    this.isSidebarOpen = false;
  }

  collapseMenu(dropdown, icon) {
    dropdown.classList.add("tw--hidden");

    var iconURL = icon.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
    var parts = iconURL.split('#');
    iconURL = parts[0] + '#chevron_down';
    icon.setAttributeNS('http://www.w3.org/1999/xlink', 'href', iconURL);
  }

  expandMenu(dropdown, icon) {
    dropdown.classList.remove("tw--hidden");

    var iconURL = icon.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
    var parts = iconURL.split('#');
    iconURL = parts[0] + '#chevron_up';
    icon.setAttributeNS('http://www.w3.org/1999/xlink', 'href', iconURL);
  }

  collapseSidebar() {
    this.sidebarMenuTarget.classList.add("tw--invisible");
    this.blanketOverlayTarget.classList.add("tw--hidden");

    var sidebarIcon = this.sidebarIconTarget.getElementsByTagName('use')[0];
    var iconURL = sidebarIcon.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
    var parts = iconURL.split('#');
    iconURL = parts[0] + '#menu';
    sidebarIcon.setAttributeNS('http://www.w3.org/1999/xlink', 'href', iconURL);
  }

  expandSidebar() {
    this.sidebarMenuTarget.classList.remove("tw--invisible");
    this.blanketOverlayTarget.classList.remove("tw--hidden");

    var sidebarIcon = this.sidebarIconTarget.getElementsByTagName('use')[0];
    var iconURL = sidebarIcon.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
    var parts = iconURL.split('#');
    iconURL = parts[0] + '#menu_close';
    sidebarIcon.setAttributeNS('http://www.w3.org/1999/xlink', 'href', iconURL);
  }
}
