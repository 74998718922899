import { ApplicationController } from '../javascript/controllers/application_controller.js';

export default class extends ApplicationController {
  static targets = [
    "counter",
    "counterNumber",
    "textArea"
  ];

  initialize() {
    this.counter();
  };

  counter() {
    if (!this.hasCounterTarget) {
      return;
    }
    let textArea = this.textAreaTarget;
    let maxLength = textArea.getAttribute('maxlength');
    let currentLength = textArea.value.length;
    let remainingLength = maxLength - currentLength;
    let trackerStart = textArea.dataset.tracker;

    if (currentLength < trackerStart) {
      this.counterTarget.classList.add('tw--invisible');
    } else {
      this.counterTarget.classList.remove('tw--invisible');
    }

    let counterNumber = this.counterNumberTarget;
    counterNumber.innerHTML = remainingLength;
  }
};
