import dayjs from 'dayjs';
import { ApplicationController } from './application_controller.js';

export default class extends ApplicationController {
  static targets = [
    'createGroupButton',
    'date',
    'destroy',
    'programDesignTerms',
    'meeting',
    'meetings',
    'meetingHeader',
    'startTime',
    'endTime'
  ];

  static values = {
    dateDisplayFormat: { type: String, default: '' },
    datevalueformatFormat: { type: String, default: '' },
    endDisplayFormat: { type: String, default: '' },
    endValueFormat: { type: String, default: '' },
    shadowEnabled: { type: Boolean, default: true },
    startDisplayFormat: { type: String, default: '' },
    startValueFormat: { type: String, default: '' }
  }

  initialize() {
    const customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(customParseFormat)
    
    if (type == 'group') {
      this.shadowEnabled = false;

      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            this.disable_meetings_header_shadow();
          } else {
            this.enable_meetings_header_shadow();
          }
        }, {
          rootMargin: '-56px',
          threshold: 1
        }
      );

      observer.observe(this.meetingHeaderTarget);

      // Set Date Formats
      var dateFields = this.dateTarget.getElementsByTagName('input');
      this.dateDisplayFormat = dateFields[1].dataset.format;
      this.dateValueFormat = dateFields[0].dataset.format;

      // Set Start Time Formats
      var startTimeFields = this.startTimeTarget.getElementsByTagName('input');
      this.startDisplayFormat = startTimeFields[1].dataset.format;
      this.startValueFormat = startTimeFields[0].dataset.format;

      // Set End Time Formats
      var endTimeFields = this.endTimeTarget.getElementsByTagName('input');
      this.endDisplayFormat = endTimeFields[1].dataset.format;
      this.endValueFormat = endTimeFields[0].dataset.format;

      this.hide_deleted_meetings();
      this.color_meetings_table();
    }
    this.toggle_create_group_button();
    this.toggle_curriculum_version_accordian();
  };

  auto_fill_meetings() {
    // Get Values from First Meeting
    var initialDateValue = this.dateTarget.getElementsByTagName('input')[0].value;
    var initialStartTimeValue = this.startTimeTarget.getElementsByTagName('input')[0].value;
    var initialEndTimeValue = this.endTimeTarget.getElementsByTagName('input')[0].value;

    for(var i = 1; i < this.meetingTargets.length; i++) {
      // Get Fields
      var dateFields = this.dateTargets[i].getElementsByTagName('input');
      var startTimeFields = this.startTimeTargets[i].getElementsByTagName('input');;
      var endTimeFields = this.endTimeTargets[i].getElementsByTagName('input');

      // Set Dates
      dateFields[0].value = dayjs(initialDateValue).add(i, 'weeks').format(this.dateValueFormat);
      dateFields[1].value = dayjs(initialDateValue).add(i, 'weeks').format(this.dateDisplayFormat);

      // Set Start Times
      startTimeFields[0].value = dayjs(initialStartTimeValue, this.startValueFormat).format(this.startValueFormat);
      startTimeFields[1].value = dayjs(initialStartTimeValue, this.startValueFormat).format(this.startDisplayFormat);

      // Set End Times
      endTimeFields[0].value = dayjs(initialEndTimeValue, this.endValueFormat).format(this.endValueFormat);
      endTimeFields[1].value = dayjs(initialEndTimeValue, this.endValueFormat).format(this.endDisplayFormat);
    }
  };

  add_meeting() {
    var meetings = this.meetingTargets;
    var newMeeting = meetings[meetings.length - 1].cloneNode(true);

    // Set date to next week
    var newMeetingDate = dayjs(newMeeting.querySelector('#meeting_date').value).add(1, 'week');
    if (newMeetingDate.isValid()) {
      newMeeting.querySelector('#meeting_date').value = newMeetingDate.format(this.dateValueFormat);
    }

    // Clear Meeting Field
    newMeeting.querySelector('#meeting_name').value = '[]';

    // Clear Details Field
    newMeeting.querySelector('#meeting_details').value = '';
    
    // Fix name values for new meeting
    newMeeting.querySelector('#meeting_name').name = `group[meetings_attributes][${meetings.length}][name]`;
    newMeeting.querySelector('#meeting_date').name = `group[meetings_attributes][${meetings.length}][date]`;
    newMeeting.querySelector('#meeting_start_time').name = `group[meetings_attributes][${meetings.length}][start_time]`;
    newMeeting.querySelector('#meeting_end_time').name = `group[meetings_attributes][${meetings.length}][end_time]`;
    newMeeting.querySelector('#meeting_details').name = `group[meetings_attributes][${meetings.length}][details]`;

    newMeeting.dataset.meetingNumber = meetings.length;
    newMeeting.classList.remove( 'tw--hidden');

    this.meetingsTarget.appendChild(newMeeting);
    this.color_meetings_table();
  };

  remove_meeting() {
    const meetingToRemove = this.meetingTargets.find(t => t.contains(event.target));

    if (confirm("Remove Meeting?\nThis will remove the meeting from your group schedule.")) {
      meetingToRemove.classList.add("tw--hidden");
      this.destroyTargets.find(t => meetingToRemove.contains(t)).value = 'true';
      
      this.color_meetings_table();
    }
  };

  color_meetings_table() {
    var meetings = this.meetingTargets;
    var alternative = true

    for(var i = 0; i < meetings.length; i++) {
      meetings[i].classList.add("tw--bg-secondary");

      if ( meetings[i].classList.contains( "tw--hidden" ) == false ) {
        if ( alternative == true  ){ 
          meetings[i].classList.remove("tw--bg-secondary");
          alternative = false
        } else {
          alternative = true
        }
      }
    };
  };

  toggle_create_group_button() {
    if (this.programDesignTermsTarget.value == '1') {
      this.createGroupButtonTarget.disabled = false;
    } else {
      this.createGroupButtonTarget.disabled = true;
    };
  };

  disable_meetings_header_shadow() {
    this.meetingHeaderTarget.classList.remove("tw--drop-shadow-lg");
    this.meetingHeaderTarget.classList.remove("tw--rounded-b-md");
    this.meetingHeaderTarget.classList.remove("tw--bg-primary");
    this.meetingHeaderTarget.classList.remove("tw--z-10");
    this.meetingHeaderTarget.classList.remove("tw--pt-1");
    this.meetingHeaderTarget.classList.remove("tw--top-[54px]"); // 2x less than top-14
  };

  enable_meetings_header_shadow() {
    this.meetingHeaderTarget.classList.add("tw--drop-shadow-lg");
    this.meetingHeaderTarget.classList.add("tw--rounded-b-md");
    this.meetingHeaderTarget.classList.add("tw--bg-primary");
    this.meetingHeaderTarget.classList.add("tw--z-10");
    this.meetingHeaderTarget.classList.add("tw--pt-1");
    this.meetingHeaderTarget.classList.add("tw--top-[54px]"); // 2x less than top-14
  };

  hide_deleted_meetings() {
    var meetings = this.meetingTargets;
    for(var i = 0; i < meetings.length; i++) {
      if (this.destroyTargets.find(t => meetings[i].contains(t)) == 'true') {
        meetings[i].classList.add("tw--hidden");
      }
    }
  }

  discard_group() {
    if (confirm("Discard Changes?\nThis will delete any unsaved changes you've entered.")) {
      if (type == 'group') {
        window.location.replace("/leaderzone/my/groups");
      } else {
        window.location.replace("/leaderzone/my/events");
      }
    }
  }

  toggle_curriculum_version_accordian() {
    var curriculumVersion;
    if (document.getElementById('group_curriculum_version_id')) {
      curriculumVersion = document.getElementById('group_curriculum_version_id').value;
    } else {
      curriculumVersion = document.getElementById('curriculum_version_id').value;
    }
    var accordians = document.querySelectorAll('#cv-accordian');
    for(var i = 0; i < accordians.length; i++) {
      if (accordians[i].dataset.id == curriculumVersion) {
        accordians[i].classList.remove('tw--hidden');
      } else {
        accordians[i].classList.add('tw--hidden');
      }
    }

  }
};
