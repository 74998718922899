import { Controller } from 'stimulus';
import { bus } from '../lib/event_bus';


export class ApplicationController extends Controller {
  initialize() {
    this._subscriptions = [];
  }

  publish(eventName, data) {
    bus.publish(eventName, data);
  }

  subscribe(eventName, callback) {
    const subscription = bus.subscribe(eventName, callback);
    this._subscriptions.push(subscription);
  }

  unsubscribe() {
    if (!this._subscriptions) return;
    this._subscriptions.forEach(unsub => unsub());
  }

  disconnect() {
    this.unsubscribe();
  }
}
