
import { ApplicationController } from './application_controller.js';

export default class extends ApplicationController {
  static targets = [
    "read-more",
    "read-more-button-text",
    "read-more-button-icon",
  ];

  click() {
    const element = this.targets.find("read-more");
    const button_text = this.targets.find("read-more-button-text");
    const button_icon = this.targets.find("read-more-button-icon");

    
    element.style.webkitLineClamp = element.style.webkitLineClamp === "3" ? "40" : "3";
    button_text.innerHTML = button_text.innerHTML === "Read More" ? "Read Less" : "Read More";
    button_icon.classList.toggle("tw--rotate-180");
  };
};
