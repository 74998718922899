import dayjs from 'dayjs';
import { ApplicationController } from '../javascript/controllers/application_controller.js';
import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = [
    'component',
    'dropdown',
    'formatDisplay',
    'formatValue',
    'inputDisplay',
    'inputValue'
  ];

  static values = {
    formatDisplay: { type: String, default: '' },
    formatValue: { type: String, default: '' },
    isDropdownOpen: { type: Boolean, default: false }
  }

  connect() {
    useClickOutside(this)
  };

  initialize() {
    this.isDropdownOpen = false;

    const customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(customParseFormat)

    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)

    this.formatDisplay = this.inputDisplayTarget.dataset.format;
    this.formatValue = this.inputValueTarget.dataset.format;

    // Try to parse the field value and update the display value
    var parsedField = dayjs(this.inputValueTarget.value, this.formatValue);
    if (parsedField.isValid()) {
      this.inputDisplayTarget.value = parsedField.format(this.formatDisplay);
    };
  };

  clickOutside(event) {
    //if(this.element === event.target || this.element.contains(event.target)) return;
    this.dropdownClose()
  };

  dropdownClose() {
    this.dropdownTarget.classList.add('tw--hidden');
    this.isDropdownOpen = false;
  }

  dropdownOpen() {
    this.fillDropdown();

    var selected_choice = undefined;
    var choices = this.dropdownTarget.children;
    for(var i = 0; i < choices.length; i++) {
      if (choices[i].dataset.value == this.inputValueTarget.value) {
        selected_choice = choices[i];
        selected_choice.classList.add('tw--bg-tertiary', 'tw--rounded-md');
      } else{
        var current_choice = choices[i];
        var next_choice = choices[i + 1];

        if (
          next_choice != undefined
          && current_choice.dataset.value < this.inputValueTarget.value
          && next_choice.dataset.value > this.inputValueTarget.value
        ) {
          var input_value = dayjs(this.inputValueTarget.value, this.formatValue);
          var current_value = dayjs(current_choice.dataset.value, this.formatValue);
          var next_value = dayjs(next_choice.dataset.value, this.formatValue);

          selected_choice = input_value.diff(current_value) < next_value.diff(input_value) ? current_choice : next_choice;
        };

        choices[i].classList.remove('tw--bg-tertiary', 'tw--rounded-md');
      };
    };

    this.dropdownTarget.classList.remove('tw--hidden');
    this.isDropdownOpen = true;

    if (selected_choice == undefined) { return };

    if (this.element.dataset.isEnd == 'true') {
      selected_choice.parentElement.scrollTop = selected_choice.offsetTop - selected_choice.parentElement.clientHeight / 3 + selected_choice.clientHeight / 2 + 8;
    } else {
      selected_choice.parentElement.scrollTop = selected_choice.offsetTop - selected_choice.parentElement.clientHeight / 2 + selected_choice.clientHeight / 2;
    };
  };

  dropdownToggle() {
    this.isDropdownOpen ? this.dropdownClose() : this.dropdownOpen();
  };

  fillDropdown() {
    const start_time = this.componentTarget.dataset.startTime;
    const end_time = this.componentTarget.dataset.endTime;
    const interval = this.componentTarget.dataset.interval;

    if (this.componentTarget.dataset.isEnd == 'true') {
      var start_time_picked = start_time;
      var time_options = this.componentTarget.parentElement.children;
      
      for(var i = 0; i < time_options.length; i++) {
        if (time_options[i].dataset.isStart == 'true') {
          start_time_picked = time_options[i].getElementsByTagName('input')[0].value;
        };
      };

      // Reset the dropdown
      while(this.dropdownTarget.lastChild) {
        this.dropdownTarget.removeChild(this.dropdownTarget.lastChild);
      };

      var start = dayjs(start_time_picked, 'HH:mm').add(this.componentTarget.dataset.endTimeMin, 'minutes');
      var end = dayjs(start_time_picked, 'HH:mm').add(this.componentTarget.dataset.endTimeMax, 'minutes');
      var interval_total = this.componentTarget.dataset.endTimeMin;

      // Don't show times that are after midnight
      if (end.isAfter(dayjs(end_time, 'HH:mm'))) {
        end = dayjs(end_time, 'HH:mm');
      };
    } else {
      var start = dayjs(start_time, 'HH:mm').add(this.componentTarget.dataset.startTimeMin, 'minutes');
      var end = dayjs(end_time, 'HH:mm').add(this.componentTarget.dataset.startTimeMax, 'minutes');
      var interval_total = this.componentTarget.dataset.startTimeMin;

      // Don't show times that are after midnight
      if (end.isAfter(dayjs(end_time, 'HH:mm'))) {
        end = dayjs(end_time, 'HH:mm');
      };
    };

    if (this.dropdownTarget.children.length > 0) { return };

    for(var i = start; i.isBefore(end.add(1, 'minute')); i = i.add(interval, 'minute')) {
      var choice = document.createElement('li');
      choice.classList = 'tw--m-1 tw--p-1 tw--detail-input tw--w-40 hover:tw--rounded-md hover:tw--bg-tertiary'
      choice.dataset.text = i.format(this.formatDisplay);
      choice.dataset.value = i.format(this.formatValue);
      choice.setAttribute('data-action', 'click->time-field-component#selectChoice');
      choice.innerHTML = i.format('h:mma');

      if (this.componentTarget.dataset.isEnd == 'true') {
        var hours = Math.floor(interval_total / 60);
        var minutes = interval_total % 60;

        var print_hours = interval_total == 60 ? 'hr' : 'hrs';
        if (hours == 0) {
          choice.innerHTML += ` (${minutes} min)`;
        } else {
          if (minutes == 0) {
            choice.innerHTML += ` (${hours} ${print_hours})`;
          } else if (minutes == 15){
            choice.innerHTML += ` (${hours}.25 ${print_hours})`;
          } else if (minutes == 30){
            choice.innerHTML += ` (${hours}.5 ${print_hours})`;
          } else if (minutes == 45){
            choice.innerHTML += ` (${hours}.75 ${print_hours})`;
          };
        };
      };

      interval_total = Number(interval_total) + Number(interval);
      this.dropdownTarget.appendChild(choice);
    };
  };

  formatInput() {
    var parsedDisplay = undefined;
    const formats = [this.formatDisplay, this.formatValue, 'h:mma', 'HH:MM', 'ha', 'HH'];

    for(var i = 0; i < formats.length; i++) {
      parsedDisplay = dayjs(this.inputDisplayTarget.value, formats[i]);
      if (parsedDisplay.isValid()) { break };
    };

    // If the view value was parsed, update the field value
    if (parsedDisplay.isValid()) {
      this.inputValueTarget.value = parsedDisplay.format(this.formatValue);
      this.inputDisplayTarget.value = parsedDisplay.format(this.formatDisplay);
    } else if (this.inputDisplayTarget.value == '') {
      this.inputDisplayTarget.value = '';
    } else {
      this.inputDisplayTarget.value = 'Invalid';
    };

    this.dropdownClose();
  };

  selectChoice(event){
    var selected = event.target.dataset;

    this.inputDisplayTarget.value = selected.text;
    this.inputValueTarget.value = selected.value;

    this.dropdownClose();
  };

  lostFocus(event) {
    if (this.element === event.target || this.element.contains(event.target)) {
      return;
    } else {
      this.formatInput();
    };
  };
  
};
