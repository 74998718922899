import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'registrationFee', 'workbookCost', 'registrationTotal', "churchCover", "participantCover", "churchDistribute", "ciDistribute", "ppay", "cpay", "ppayLanguage", "alpha", "beta", "omega", "epsilon", "gamma", "mu", "delta" ];

  initialize() {
    this.checkquestionState();
  }

  connect() {  }

  calculateTotal() {
    var total_amount = Number(this.registrationFeeTarget.value) + Number(this.workbookCostTarget.innerText)
    this.registrationTotalTarget.innerHTML = total_amount.toFixed(2);
    this.registrationTotalTarget.style.fontWeight = '600';
  }

  checkquestionState() {
    var hasCIDistributeTarget = false;
    if (this.ciDistributeTarget) {
      hasCIDistributeTarget = true;
      this.ciDistributeTarget.removeAttribute('disabled');
    };

    var hasPpayLanguageTarget =  false;
    if (this.ppayLanguageTarget) {
      hasPpayLanguageTarget = true;
      this.ppayLanguageTarget.style.color = "#686868";
    }

    this.muTarget.style.display = 'none';

    var hasChurchCoverTarget =  false;
    if (this.churchCoverTarget) { hasChurchCoverTarget = true };

    var hasParticipantCoverTarget =  false;
    if (this.participantCoverTarget) { hasParticipantCoverTarget = true };

    if (hasChurchCoverTarget && this.churchCoverTarget.checked == true && this.churchDistributeTarget.checked == true) {
      this.alphaTarget.style.display = 'inline';
      this.betaTarget.style.display = 'none';
      this.muTarget.style.display = 'none';
      if (hasPpayLanguageTarget) {this.ppayLanguageTarget.style.color = "#C0C0C0";}
      if (hasCIDistributeTarget) {this.ciDistributeTarget.setAttribute('disabled', true);}
      $(this.churchDistributeTarget).prop( "checked", true );
      this.gammaTarget.style.display = 'none';
      this.epsilonTarget.style.display = 'inline';
      this.omegaTarget.style.display = 'none';
    } else if (hasChurchCoverTarget && this.churchCoverTarget.checked == true && hasCIDistributeTarget && this.ciDistributeTarget.checked == true){
      if (hasPpayLanguageTarget) {this.ppayLanguageTarget.style.color = "#C0C0C0";}
      this.ciDistributeTarget.setAttribute('disabled', true);
      $(this.churchDistributeTarget).prop( "checked", true );
      this.gammaTarget.style.display = 'none';
      this.epsilonTarget.style.display = 'inline';
      this.omegaTarget.style.display = 'none';
    } else if (hasParticipantCoverTarget && this.participantCoverTarget.checked == true && this.churchDistributeTarget.checked == true) {
      this.alphaTarget.style.display = 'none';
      this.betaTarget.style.display = 'inline';
      this.muTarget.style.display = 'block';
      this.gammaTarget.style.display = 'none';
      this.epsilonTarget.style.display = 'inline';
      this.omegaTarget.style.display = 'none';
    } else if (hasParticipantCoverTarget && this.participantCoverTarget.checked == true && hasCIDistributeTarget && this.ciDistributeTarget.checked == true) {
      this.gammaTarget.style.display = 'block';
      this.alphaTarget.style.display = 'inline';
      this.betaTarget.style.display = 'none';
      this.epsilonTarget.style.display = 'none';
      this.omegaTarget.style.display = 'inline';
    } 
  };
}
