import { ApplicationController } from './application_controller';
export default class extends ApplicationController {
  connect() {
    if (this.getCookieValue('_lz_langtz') == null) {
      this.setGeoCookie();
    }
  }

  getCookieValue(name) {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop();
  }

  setGeoCookie() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const language = navigator.language;

    const lang_tz = '{"lang":"' + language + '","tz":"' + timezone + '"}';

    document.cookie = '_lz_langtz=' + lang_tz + '; path=/;';
  }
}
