import dayjs from 'dayjs'
import { Datepicker } from 'vanillajs-datepicker';
import { ApplicationController } from '../javascript/controllers/application_controller.js';

export default class extends ApplicationController {
  static targets = [
    'component',
    'formatDisplay',
    'formatValue',
    'inputDisplay',
    'inputValue'
  ];

  static values = {
    self: { type: Object, default: null },
    formatDisplay: { type: String, default: '' },
    formatValue: { type: String, default: '' },
    days: { type: Array, default: [] },
    months: { type: Array, default: [] }
  }

  initialize() {
    this.days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    this.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(customParseFormat)

    // Set Formats
    this.formatDisplay = this.inputDisplayTarget.dataset.format;
    this.formatValue = this.inputValueTarget.dataset.format;

    // Try to parse the field value and update the display value
    var parsedField = dayjs(this.inputValueTarget.value, this.formatValue);
    if (parsedField.isValid()) {
      this.inputDisplayTarget.value = parsedField.format(this.formatDisplay);
    };

    this.datepicker = new Datepicker(this.inputDisplayTarget, {
      autohide: true,
      buttonClass: 'tw--button',
      dateDelimiter: '',
      format: 'D, M d, yyyy',
      showOnFocus: false,
      updateOnBlur: false
    });

    this.datepicker.setDate(parsedField.toDate());
  };

  formatInput() {
    var parsableDisplay = '';

    // Can't parse day, so remove it from display value before parsing the date
    if (this.days.includes(this.inputDisplayTarget.value.slice(0, 3))) {
      parsableDisplay = this.inputDisplayTarget.value.slice(4);
    } else {
      parsableDisplay = this.inputDisplayTarget.value      
    };

    var parsedDisplay = undefined;
    const formats = [this.formatDisplay, this.formatValue, 'MMM D, YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY', 'M/D/YYYY', 'M/D/YY'];

    // Try to parse the display value
    for(var i = 0; i < formats.length; i++) {
      parsedDisplay = dayjs(parsableDisplay, formats[i]);
      if (parsedDisplay.isValid()) { break };
    };

    // If the parsed date is valid, update the input value
    if (parsedDisplay.isValid()) {
      this.inputValueTarget.value = parsedDisplay.format(this.formatValue);
      this.inputDisplayTarget.value = parsedDisplay.format(this.formatDisplay);

      // Update the datepicker to the parsed date
      this.datepicker.setDate(parsedDisplay.toDate());
    };
  };
};
