import { ApplicationController } from './application_controller';
export default class extends ApplicationController {
  static targets = [
    'button',
    'display',
    'form'
  ];

  edit() {
    this.displayTarget.classList.add('tw--hidden');
    this.buttonTarget.classList.add('tw--hidden');
    this.formTarget.classList.remove('tw--hidden');
  }

  discard() {
    if (confirm("Unsaved Changes\nYou have unsaved changes on this page that will be lost. Do you still want to exit?")) {
      this.displayTarget.classList.remove('tw--hidden');
      this.buttonTarget.classList.remove('tw--hidden');
      this.formTarget.classList.add('tw--hidden');
    }
  }
}
