import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'display', 'edit', 'focusField' ];

  edit(event) {
    event.preventDefault();
    this.displayTarget.style.display = "none";
    this.editTarget.style.display = "block";

    if (this.hasFocusFieldTarget) this.focusFieldTarget.focus();
  }
}
