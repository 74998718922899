import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'checkbox', 'checked', 'unchecked' ];

  initialize() {
    this._visibleType = this.data.get('visible-type');
  }

  connect() {
    this.render();
  }

  render() {
    this.checkedTarget.style.display = (this.checkboxTarget.checked) ? this._visibleType : 'none';
    this.uncheckedTarget.style.display = (this.checkboxTarget.checked) ? 'none' : this._visibleType;
  }
}
