import { ApplicationController } from './application_controller.js';

export default class extends ApplicationController {
  static targets = [
    'curriculumVersionDetail',
    'selectedCurriculumWorkbookPrice',
    'ministryLabel'
  ];

  initialize() {
    this.toggle_language_help_text();
    this.update_curriculum_details();
  };

  toggle_language_help_text() {
    const languageSelect = JSON.parse(document.getElementById('group_lang_discuss').value);
    const languageHelpText = document.getElementById('group_lang_discuss_help_text');

    if (languageSelect.includes('English') && languageSelect.length == 1) {
      languageHelpText.classList.add('tw--hidden');
    } else {
      languageHelpText.classList.remove('tw--hidden');
    };
  };

  update_curriculum_details() {
    if (document.getElementById('curriculum_version_id').type != 'hidden') {
      this.update_ministry_label();
      var selected_cv_id = document.getElementById('group_curriculum_version_id').value;
      var available_cvs = this.curriculumVersionDetailTargets;

      for(var i = 0; i < available_cvs.length; i++) {
        var cv = available_cvs[i];

        if (cv.dataset.cvId == selected_cv_id) {
          cv.classList.remove('tw--hidden');
          this.selectedCurriculumWorkbookPriceTarget.value = cv.dataset.workbookPrice;
        } else {
          cv.classList.add('tw--hidden');
        };
      };
    };
  };

  update_ministry_label() {
    var ministryName = document.getElementById('group_curriculum_version_id_visible').value.split(' ')[0];

    this.ministryLabelTargets.forEach((label) => {
      label.innerText = ministryName;
    })
  };
};
