import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'profileMenu' ];

  static values = {
    isProfileMenuOpen: { type: Boolean, default: false }
  } 

  toggleProfileMenu() {
    this.isProfileMenuOpen ? this.collapseProfileMenu() : this.expandProfileMenu();
    this.isProfileMenuOpen = !this.isProfileMenuOpen;
  }

  closeProfileMenu(e) {
    if(this.element === e.target || this.element.contains(e.target)) return;

    this.collapseProfileMenu();
    this.isProfileMenuOpen = false;
  }

  collapseProfileMenu() {
    this.profileMenuTarget.classList.add("tw--hidden");
  }

  expandProfileMenu() {
    this.profileMenuTarget.classList.remove("tw--hidden");
  }
}
