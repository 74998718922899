import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'dropdown', 'label', 'option', 'toggleAll' ];

  connect() {
    $(this.dropdownTarget).on('hide.bs.dropdown', () => { this.submitFilter() });

    this.resetToggleAll();
    this.setLabelToSelectedOptions();
  }

  clickMenu(event) {
    // Prevent the menu from closing after an option is clicked.
    event.stopPropagation();
  }

  clickOption() {
    this.resetToggleAll();
    this.setLabelToSelectedOptions();
  }

  toggleAll(event) {
    event.preventDefault();

    if (this.toggleAllTarget.dataset.state === 'clear') {
      this.setAllOptions(false);
      this.setToggleAllState('select');
    } else {
      this.setAllOptions(true);
      this.setToggleAllState('clear');
    }

    this.setLabelToSelectedOptions();
  }

  submitFilter() {
    let selected = this.selectedOptions;
    if (selected.length === this.optionTargets.length) selected = [];

    this.publish('search:filter', {
      field: this.data.get('field'),
      value: selected.map(option => option.dataset.value).join(',')
    });
  }

  resetToggleAll() {
    const clear = this.optionTargets.every(option => option.checked);
    this.setToggleAllState(clear ? 'clear' : 'select');
  }

  setToggleAllState(state) {
    this.toggleAllTarget.innerHTML = state === 'clear' ? 'Clear All' : 'Select All';
    this.toggleAllTarget.dataset.state = state;
  }

  setLabelToSelectedOptions() {
    const selected = this.selectedOptions;

    if (selected.length === 0 || selected.length === this.optionTargets.length) {
      this.labelTarget.innerHTML = this.data.get('allLabel');
    } else {
      const optionLabels = selected.map(option => option.parentNode.textContent.replace(/^\s+|\s+$/g, ''));
      this.labelTarget.innerHTML = optionLabels.join(', ');
    }
  }

  setAllOptions(value) {
    this.optionTargets.forEach(option => option.checked = value);
  }

  get selectedOptions() {
    return this.optionTargets.filter(option => option.checked);
  }
}
