import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'drawer', 'drawerIcon', 'menuDropDown', 'menuIcon', 'blanketOverlay' ];

  static values = {
    isDrawerOpen: { type: Boolean, default: false }
  } 
  
  initialize() {
    this.isDrawerOpen = false;
  }

  toggleSearch() {
    document.getElementById('nav-search-form').classList.toggle("lg:tw--hidden");
    document.getElementById('nav-search-icon').classList.toggle("tw--hidden");
    document.getElementById('nav-items').classList.toggle("lg:tw--hidden");
    document.getElementsByClassName('search-field')[0].focus();
  }

  toggleMenu(e) {
    var w = window.innerWidth;
    if (w >= 1280) {
      const dropdown = this.menuDropDownTargets.find(target => target.id == e.params.id);
      const icon = this.menuIconTargets.find(target => target.id == e.params.id).getElementsByTagName("use")[0];

      dropdown.classList.toggle("xl:tw--hidden");

      var iconURL = icon.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
      var parts = iconURL.split('#');

      if (parts[1] == 'chevron_down') {
        iconURL = parts[0] + '#chevron_up';
      } else {
        iconURL = parts[0] + '#chevron_down';
      }

      icon.setAttributeNS('http://www.w3.org/1999/xlink', 'href', iconURL);
    }
  }

  toggleDrawer() {
    this.isDrawerOpen ? this.collapseDrawer() : this.expandDrawer();
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  closeDrawer(e) {
    this.collapseDrawer();
    this.isDrawerOpen = false;
  }

  collapseDrawer() {
    if (this.isDrawerOpen ) {
      this.drawerTarget.classList.add("tw--invisible");
      this.blanketOverlayTarget.classList.add("tw--hidden");

      var drawerIcon = this.drawerIconTarget.getElementsByTagName("use")[0];
      var iconURL = drawerIcon.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
      
      var parts = iconURL.split('#');
      iconURL = parts[0] + '#menu';
      drawerIcon.setAttributeNS('http://www.w3.org/1999/xlink', 'href', iconURL);
    }
  }

  expandDrawer() {
    this.drawerTarget.classList.remove("tw--invisible");
    this.blanketOverlayTarget.classList.remove("tw--hidden");

    var drawerIcon = this.drawerIconTarget.getElementsByTagName("use")[0];
    var iconURL = drawerIcon.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
    var parts = iconURL.split('#');
    iconURL = parts[0] + '#menu_close';
    drawerIcon.setAttributeNS('http://www.w3.org/1999/xlink', 'href', iconURL);
  }
}
