import { ApplicationController } from '../javascript/controllers/application_controller.js';
import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = [
    'choice',
    'iconClosed',
    'iconOpen',
    'inputText',
    'inputValue',
    'selectableChoice'
  ];

  static values = {
    isSelectOpen: { type: Boolean, default: false }
  };

  connect() {
    useClickOutside(this)
  }

  initialize() {
    this.isSelectOpen = false;
  };

  clickOutside(event) {
    this.selectClose()
  };

  selectClose() {
    this.iconClosedTarget.classList.remove('tw--hidden');
    this.iconOpenTarget.classList.add('tw--hidden');
    this.choiceTarget.classList.add('tw--hidden');

    this.isSelectOpen = false;
  }

  selectOpen() {
    this.iconClosedTarget.classList.add('tw--hidden');
    this.iconOpenTarget.classList.remove('tw--hidden');
    this.choiceTarget.classList.remove('tw--hidden');

    this.isSelectOpen = true;
  };

  selectToggle() {
    this.isSelectOpen ? this.selectClose() : this.selectOpen();
  };


  selectChoice(event) {
    var selected = event.target.dataset;

    this.inputTextTarget.value = selected.text;
    this.inputValueTarget.value = selected.value;
    this.selectClose();

    this.inputTextTarget.dispatchEvent(new Event('change'));
  };
};
