import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'label', 'option' ];

  connect() {
    this.subscribe('search:changeFilter', data => this.selectOption(data));
    const initialOption = this.optionWithValue(this.data.get('initialValue'));

    if (initialOption) {
      this.label = initialOption.textContent;
    }
  }

  selectOption({field, value}) {
    if (this.data.get('field') === field) {
      const option = this.optionWithValue(value);
      this.applyOption(option.textContent, value)
    }
  }

  clickOption(event) {
    event.preventDefault();
    this.applyOption(event.target.textContent, event.target.dataset.value)
  }

  applyOption(label, value) {
    this.label = label;

    this.publish('search:filter', {
      field: this.data.get('field'),
      value: value
    });
  }

  optionWithValue(value) {
    return this.optionTargets.find(option => option.dataset.value === value);
  }

  set label(value) {
    this.labelTarget.innerHTML = value;
  }
}
