import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { ApplicationController } from './application_controller';
export default class extends ApplicationController {
  static targets = [
    'mapbox',
    'coordinates',
    'query',
    'submit'
  ];

  connect() {
    this.configureMapbox();
  }

  configureMapbox() {
    this.mapboxTargets.forEach( (elem) => { 
    
      window['mapbox'+ elem.id] = new MapboxGeocoder({
        accessToken: this.queryTarget.dataset.mapboxApiKey,
        placeholder: 'Postal code or city/state',
        proximity: 'ip',
        types: 'country,region,place,postcode,locality,neighborhood'
      });

      window['mapbox'+ elem.id].addTo(elem); 
    
      window['mapbox'+ elem.id].on('result', (e) => {
        const searchlatString = JSON.stringify(e.result.center[1], null, 2);
        const searchlonString = JSON.stringify(e.result.center[0], null, 2);
        const searchplaceString = JSON.stringify(e.result.place_name, null, 2);
        this.coordinatesTargets.forEach( (elem) => { elem.value = "(" + searchlatString + ", " + searchlonString + ")" })
        this.queryTargets.forEach( (elem) => { elem.value = searchplaceString.slice(1, -1); })
        this.submitForm();
      });

      window['mapbox'+ elem.id].on('clear', () => {
        this.coordinatesTargets.forEach( (elem) => { elem.value = ''})
        this.queryTargets.forEach( (elem) => { elem.value = '' })
      });
    })
  }

  next() {
    var geocoderInputs = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input');
    var geocoderInput = '';
    if (typeof geocoderInputs[1] !== 'undefined' && geocoderInputs[0].value.length === 0 && this.mapboxTarget[1] && this.mapboxTargets[1]) {
      geocoderInput =  geocoderInputs[1].value;
      window['mapbox'+ this.mapboxTargets[1].id].query(geocoderInput);
      window['mapbox'+ this.mapboxTarget[1].id].on('result', (e) => {
        const searchlatString = JSON.stringify(e.result.center[1], null, 2);
        const searchlonString = JSON.stringify(e.result.center[0], null, 2);
        const searchplaceString = JSON.stringify(e.result.place_name, null, 2);
        this.coordinatesTargets[1].value = "(" + searchlatString + ", " + searchlonString + ")"
        this.queryTargets[1].value = searchplaceString.slice(1, -1); 
      })
      this.submitForm();
    } else {
      geocoderInput =  geocoderInputs[0].value;
      window['mapbox'+ this.mapboxTargets[0].id].query(geocoderInput);
      window['mapbox'+ this.mapboxTargets[0].id].on('result', (e) => {
        const searchlatString = JSON.stringify(e.result.center[1], null, 2);
        const searchlonString = JSON.stringify(e.result.center[0], null, 2);
        const searchplaceString = JSON.stringify(e.result.place_name, null, 2);
        this.coordinatesTargets[0].value = "(" + searchlatString + ", " + searchlonString + ")"
        this.queryTargets[0].value = searchplaceString.slice(1, -1); 
      })
      this.submitForm();
    }
  }

  submitForm() {
    this.submitTarget.click();
  }
}
