import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'acceptPolicyButton', 'agreePolicyCheckbox' ];


  toggle_accept_button() {
    if (this.agreePolicyCheckboxTarget.value == '1') {
      this.acceptPolicyButtonTarget.disabled = false;
    } else {
      this.acceptPolicyButtonTarget.disabled = true;
    };
  };
}
