import { ApplicationController } from './application_controller';
import Client from 'shopify-buy';

export default class extends ApplicationController {
  static targets = [
    'waiting',
    'error'
  ];

  connect() {
    this.checkout();
  }

  retry(event) {
    event.preventDefault();

    this.errorTarget.style.display = 'none';
    this.waitingTarget.style.display = 'block';

    this.checkout();
  }

  checkout() {
    // First, attempt to prepopulate checkout with user-supplied billing / shipping fields
    this.checkoutProducts(true).catch((err) => {
      // If Shopify objects, try again without those fields
      this.checkoutProducts(false).catch((err) => {
        this.waitingTarget.style.display = 'none';
        this.errorTarget.style.display = 'block';

        this.application.handleError(err);
      });
    });
  }

  async checkoutProducts(prepopulateCheckout) {
    let attributes = [
      { key: 'return_url', value: this.data.get('returnUrl') },
      { key: 'user_id', value: this.data.get('userId') }
    ];

    const purchaserId = this.data.get('purchaserId');
    if (purchaserId) {
      attributes.push({ key: 'purchaser_id', value: purchaserId });
    }

    const registerableId = this.data.get('registerableId');
    if (registerableId) {
      attributes.push({ key: 'registerable_id', value: registerableId });
    }

    let checkoutFields = { customAttributes: attributes };

    if (prepopulateCheckout) {
      const prepopulateCheckoutFields = this.prepopulateCheckoutFields;
      const shippingAddress = prepopulateCheckoutFields.shipping_address;

      if (shippingAddress) {
        checkoutFields = {
          ...checkoutFields,
          allowPartialAddresses: true,
          shippingAddress: {
            firstName: prepopulateCheckoutFields.first_name,
            lastName: prepopulateCheckoutFields.last_name,
            address1: shippingAddress.address,
            address2: shippingAddress.address2,
            city: shippingAddress.city,
            province: shippingAddress.state,
            zip: shippingAddress.zip,
            country: shippingAddress.country
          }
        };
      }
    }

    const checkout = await this.client.checkout.create(checkoutFields);

    const productIds = JSON.parse(this.data.get('productIds'));

    const lineItems = await Promise.all(productIds.map(async productId => {
      const fullProductId = btoa("gid://shopify/Product/" + productId);
      const storeProduct = await this.client.product.fetch(fullProductId);
      return {
        variantId: storeProduct.variants[0].id,
        quantity: 1
      }
    }));

    // await this.client.checkout.addDiscount(checkout.id, 'test-discount')

    await this.client.checkout.addLineItems(checkout.id, lineItems);

    // Redirect to Shopify checkout page
    window.location.replace(checkout.webUrl);
  }

  get client() {
    this._client = this._client || Client.buildClient({
      domain: shopifyConfig.domain,
      storefrontAccessToken: shopifyConfig.storefrontAccessToken
    });
    return this._client;
  }

  get prepopulateCheckoutFields() {
    return JSON.parse(this.data.get('prepopulateCheckoutFields'));
  }
}
