import { ApplicationController } from '../javascript/controllers/application_controller.js';

export default class extends ApplicationController {
  static targets = [
    'checkboxField',
    'checkedIcon',
    'uncheckedIcon',
  ];

  initialize() {
    this.setChecked();
  };

  setChecked() {
    if (this.checkboxFieldTarget.value == '1') {
      this.checkedIconTarget.classList.remove('tw--hidden');
      this.uncheckedIconTarget.classList.add('tw--hidden');
    } else {
      this.checkedIconTarget.classList.add('tw--hidden');
      this.uncheckedIconTarget.classList.remove('tw--hidden');
    };
  };
  
  toggleChecked() {
    this.checkboxFieldTarget.value = this.checkboxFieldTarget.value == '0' ? '1' : '0';
    this.setChecked();
  };
};
