import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'headerDropDown', 'headerDropDownIcon', 'dropDown' ];

  static values = {
    isMenuOpen: { type: Boolean, default: false }
  } 
  
  initialize() {
    this.isMenuOpen = false;
  }

  toggleHeaderDropDown() {
    this.isMenuOpen = !this.isMenuOpen;
    
    this.headerDropDownIconTarget.classList.toggle("tw--rotate-180");
    this.dropDownTarget.classList.toggle("tw--hidden");
  }

  hide(e) {
    if(this.element === e.target || this.element.contains(e.target)) return;

    this.dropDownTarget.classList.add("tw--hidden");
    this.headerDropDownIconTarget.classList.remove("tw--rotate-180");
    this.isMenuOpen = false;
  }
}